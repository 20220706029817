import { transparentCss } from 'components/_common/Common'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import FormSearchRoom from 'components/_forms/FormSearchRoom'
import { useAppSelector } from 'hooks/useRedux'
import { useTranslation } from 'react-i18next'

import { selectFitCustomResolution } from 'redux/slices/app'
import styled, { css } from 'styled-components'

const Container = styled(Common.Div)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 48px 16px;
    align-items: center;
    background: url(${theme.assets.searchBackground}) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
  `
)

const FormSearchRoomContainer = styled(Common.Card)(
  ({ isMobile }) => css`
    padding: ${isMobile ? '32px 24px' : '32px 54px'};
    flex-direction: row;
    width: ${isMobile ? 'min(415px, 100%)' : '1092px'};
    ${transparentCss};
  `
)

const ViewMakeReservationSearchRooms = () => {
  const { t } = useTranslation()
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 1266)
  )

  return (
    <Container
      isMobile={isMobile}
      w="100%"
    >
      <Typography.H1
        mb={4}
        align="center"
        tertiary
        bold
        s={40}
      >
        {t('findTheRoom.header')}
      </Typography.H1>
      <Typography.H2
        mb={6}
        align="center"
        tertiary
        s={32}
      >
        {t('findTheRoom.desc')}
      </Typography.H2>
      <FormSearchRoomContainer isMobile={isMobile}>
        <FormSearchRoom />
      </FormSearchRoomContainer>
    </Container>
  )
}

export default ViewMakeReservationSearchRooms
