import { enUS, pl } from 'date-fns/locale'

const localeMap: { [key: string]: Locale } = {
  en: enUS,
  pl,
}

export const getDateFnsLocale = (language: string): Locale =>
  localeMap[language] || enUS

export const countDaysBetweenDates = (
  startDate: string | Date,
  endDate: string | Date
) => {
  // Convert strings to Date objects
  const start = new Date(startDate)
  const end = new Date(endDate)

  // Calculate the difference in milliseconds
  const difference = Math.abs(end.getTime() - start.getTime())

  // Convert milliseconds to days
  return Math.ceil(difference / (1000 * 60 * 60 * 24))
}
