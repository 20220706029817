import stepPrintCard from 'assets/graphics/stepCardPrinting.svg'
import stepGraphics from 'assets/graphics/stepReservationDetails.svg'
import * as Common from 'components/_common/Common'
import {
  RESERVATION_STEP_DETAILS,
  RESERVATION_STEP_DETAILS_INFO,
  RESERVATION_STEP_PAYMENT,
  RESERVATION_STEP_PRINT_CARDS,
} from 'constants/app'
import { useAppSelector } from 'hooks/useRedux'
import React from 'react'
import { selectFitCustomResolution, selectIsKiosk } from 'redux/slices/app'
import styled, { css } from 'styled-components'
import { TReservationStepType } from 'types'

const mapStepGraphics = (step: TReservationStepType) => {
  switch (step) {
    case RESERVATION_STEP_DETAILS:
      return stepGraphics
    case RESERVATION_STEP_PAYMENT:
      return stepGraphics
    case RESERVATION_STEP_PRINT_CARDS:
      return stepPrintCard
    default:
      return stepGraphics
  }
}

export const StyledStepGraphics = styled(Common.SvgIcon)(
  () => css`
    width: 100%;
    max-width: 678px;
    max-height: 63px;
  `
)

export const StepBar = styled(Common.Div)<{ isActive?: boolean }>(
  ({ theme: { colors }, isActive }) => css`
    width: 54px;
    height: 5px;
    background: ${isActive ? colors.primary : colors.border};
  `
)

const ReservationSteps = ({
  step,
  withStepBars = true,
}: {
  step: TReservationStepType
  withStepBars?: boolean
}) => {
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 600)
  )
  const isKiosk = useAppSelector(selectIsKiosk)

  return (
    <>
      <Common.Div
        flex="row"
        justifyContent="center"
      >
        <StyledStepGraphics
          src={mapStepGraphics(step)}
          mb={4}
          isMobile={isMobile}
        />
      </Common.Div>
      {withStepBars && (
        <Common.Div
          flex="row"
          gap="8px"
          justifyContent="center"
          mb={isKiosk ? 0 : 4}
        >
          <StepBar isActive={step === RESERVATION_STEP_DETAILS} />
          {isKiosk && (
            <StepBar isActive={step === RESERVATION_STEP_DETAILS_INFO} />
          )}
          <StepBar isActive={step === RESERVATION_STEP_PAYMENT} />
          <StepBar isActive={step === RESERVATION_STEP_PRINT_CARDS} />
        </Common.Div>
      )}
    </>
  )
}

export default ReservationSteps
