import IconArrowLeft from 'assets/icons/iconArrowLeft.svg'
import * as Common from 'components/_common/Common'

import { TButtonClickHandler } from 'components/_common/commonStyles'
import { useTranslation } from 'react-i18next'
import * as Typography from 'components/_common/Typography'

const BackButton = ({ onClick }: { onClick: TButtonClickHandler }) => {
  const { t } = useTranslation()
  return (
    <Common.Div
      flex="row"
      gap="8px"
      alignItems="center"
      onClick={onClick}
    >
      <Common.SvgIcon src={IconArrowLeft} />
      <Typography.Body2 secondary>{t('navigation.back')}</Typography.Body2>
    </Common.Div>
  )
}

export default BackButton
