import IconCalendar from 'assets/icons/IconCalendarSecondary.svg'
import IconIndicatorDown from 'assets/icons/IconIndicatorDown.svg'
import IconIndicatorUp from 'assets/icons/IconIndicatorUp.svg'

import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import { TFormSearchRoomChosenInput } from 'components/_forms/FormSearchRoom'
import { format } from 'date-fns'
import { useOnClickOutside } from 'hooks/useClickOutside'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { DateRange, Range } from 'react-date-range'
import { Controller, UseFormReturn } from 'react-hook-form'
import {
  selectFitCustomResolution,
  selectIsKiosk,
  setKioskInputOpened,
} from 'redux/slices/app'
import { selectRoomSearchParams } from 'redux/slices/room'
import styled, { css } from 'styled-components'
import { IFindRoomForm } from 'types/room'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css'
import { useTranslation } from 'react-i18next'
import { getDateFnsLocale } from 'utils/dates'

interface IInputDateRangeProps {
  form: UseFormReturn<IFindRoomForm>
  selectedInput: TFormSearchRoomChosenInput
  setSelectedInput: Dispatch<SetStateAction<TFormSearchRoomChosenInput>>
}

const StyledDateRange = styled(DateRange)<{ shouldSqueeze?: boolean }>(
  ({ shouldSqueeze }) => css`
    width: ${shouldSqueeze ? '100%' : '100%'};
    scale: ${shouldSqueeze ? '0.9' : '1'};
    background: none;
  `
)

const StyledDropdownModal = styled(Common.DropdownModal)<{
  shouldSqueeze?: boolean
}>(
  ({ shouldSqueeze, theme: { spacing, isKiosk, colors } }) => css`
    left: ${shouldSqueeze ? '0' : 'auto'};
    padding: ${shouldSqueeze || isKiosk ? '0' : spacing(2)};
    width: ${shouldSqueeze ? '100%' : 'auto'};

    .rdrWeekDay {
      color: ${colors.primary};
    }
  `
)

const InputDateRange = ({
  form,
  selectedInput,
  setSelectedInput,
}: IInputDateRangeProps) => {
  const ref = useRef(null)
  const isKiosk = useAppSelector(selectIsKiosk)
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()
  const language: string = i18n.language
  const dateLocale = getDateFnsLocale(language)
  const searchData = useAppSelector(selectRoomSearchParams)

  useOnClickOutside(ref, () => {
    setSelectedInput(null)
    if (isKiosk) {
      dispatch(setKioskInputOpened(false))
    }
  })

  const shouldSqueezeDatepicker = useAppSelector(state =>
    selectFitCustomResolution(state, 440)
  )

  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 1266)
  )
  const shouldVerticalCalendar = isKiosk
    ? false
    : useAppSelector(state => selectFitCustomResolution(state, 1174))

  const { control, formState } = form
  const [state, setState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: undefined,
      key: 'selection',
    },
  ])

  const datePeriodsText = () => {
    if (state[0].startDate && state[0].endDate) {
      return `${format(state[0].startDate, 'EEE d MMM', {
        locale: dateLocale,
      })} - ${format(state[0].endDate, 'EEE d MMM', {
        locale: dateLocale,
      })}`
    }
    return `Check-in-date - Check-out-date`
  }
  const isOpen = selectedInput === 'date'

  const handleIconClick = () => {
    if (isOpen) {
      setSelectedInput(null)
      if (isKiosk) {
        dispatch(setKioskInputOpened(false))
      }
    } else {
      setSelectedInput('date')
      if (isKiosk) {
        dispatch(setKioskInputOpened(true))
      }
    }
  }

  const error = formState?.errors.stayDate?.startDate as { message: string }

  useEffect(() => {
    if (searchData.stayDate.startDate && searchData.stayDate.endDate) {
      setState([
        {
          startDate: new Date(searchData.stayDate.startDate),
          endDate: new Date(searchData.stayDate.endDate),
          key: 'selection',
        },
      ])
    }
  }, [searchData])

  return (
    <Common.Div
      mb={isMobile ? 2 : 0}
      flex="column"
      gap="8px"
    >
      <Common.DropdownWithIcon
        onClick={handleIconClick}
        isMobile={isMobile}
      >
        <Common.SvgIcon
          src={IconCalendar}
          w="16px"
          h="18px"
          mr={1}
        />
        <Typography.Body2
          secondary
          mr={2}
        >
          {datePeriodsText()}
        </Typography.Body2>
        <Common.SvgIcon
          src={isOpen ? IconIndicatorUp : IconIndicatorDown}
          w="10px"
          h="10px"
        />
      </Common.DropdownWithIcon>

      {isOpen && (
        <StyledDropdownModal
          ref={ref}
          shouldSqueeze={shouldSqueezeDatepicker}
        >
          <Controller
            name="stayDate"
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Common.Div>
                  <StyledDateRange
                    shouldSqueeze={shouldSqueezeDatepicker}
                    showDateDisplay={false}
                    onChange={item => {
                      setState([item.selection])
                      onChange(item.selection)
                    }}
                    ranges={state}
                    months={2}
                    direction={
                      shouldVerticalCalendar ? 'vertical' : 'horizontal'
                    }
                  />
                </Common.Div>
              )
            }}
          />
        </StyledDropdownModal>
      )}
      {error && (
        <Typography.ErrorMessage>
          {error?.message ?? ''}
        </Typography.ErrorMessage>
      )}
    </Common.Div>
  )
}

export default InputDateRange
