import BackButton from 'components/_common/BackButton'
import * as Common from 'components/_common/Common'
import NextButton from 'components/_common/NextButton'
import React from 'react'

const BackNextButtonsKiosk = ({
  handleBackButton,
  handleNextButton,
}: {
  handleBackButton: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleNextButton: (e: React.MouseEvent<HTMLButtonElement>) => void
}) => {
  return (
    <>
      <Common.Div
        flex="row"
        justifyContent="space-between"
        mt={2}
        w="100%"
        // position="absolute"
        // bottom={45}
        // px={6}
        // py={2}
      >
        <BackButton
          onClick={e =>
            handleBackButton(e as React.MouseEvent<HTMLButtonElement>)
          }
        />
        <NextButton
          onClick={e =>
            handleNextButton(e as React.MouseEvent<HTMLButtonElement>)
          }
        />
      </Common.Div>
    </>
  )
}

export default BackNextButtonsKiosk
