import BackButton from 'components/_common/BackButton'
import * as Common from 'components/_common/Common'
import NextButton from 'components/_common/NextButton'
import * as Typography from 'components/_common/Typography'
import ReservationSteps from 'components/_views/ReservationDetailsSteps/ReservationSteps'
import { RESERVATION_STEP_PAYMENT } from 'constants/app'
import {
  ROUTE_MAKE_RESERVATION_DETAILS,
  ROUTE_MAKE_RESERVATION_PAYMENT_COMPLETED,
} from 'constants/routes'
import { useAppSelector } from 'hooks/useRedux'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { selectFitCustomResolution } from 'redux/slices/app'

import { ViewYourReservationContainer } from 'views/desktopMobile/ViewYourReservation.styled'

const ViewMakeReservationPayment = () => {
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 600)
  )
  const navigate = useNavigate()

  // TODO: Check if reservation form exists, otherwise redirect back

  return (
    <ViewYourReservationContainer isMobile={isMobile}>
      <ReservationSteps step={RESERVATION_STEP_PAYMENT} />
      <Typography.H4 mb={2}>Payment</Typography.H4>
      <Common.Div
        flex="row"
        justifyContent="space-between"
        mt={2}
        w="100%"
      >
        <BackButton onClick={() => navigate(ROUTE_MAKE_RESERVATION_DETAILS)} />
        <NextButton
          onClick={() => navigate(ROUTE_MAKE_RESERVATION_PAYMENT_COMPLETED)}
        />
      </Common.Div>
    </ViewYourReservationContainer>
  )
}

export default ViewMakeReservationPayment
