import * as Common from 'components/_common/Common'
import { KioskViewContainer } from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import BackNextButtonsKiosk from 'components/_views/ReservationDetailsSteps/BackNextButtonsKiosk'
import ReservationSteps from 'components/_views/ReservationDetailsSteps/ReservationSteps'
import { RoomDetails } from 'components/_views/Room/RoomDetails'
import { RESERVATION_STEP_DETAILS } from 'constants/app'
import {
  ROUTE_MAKE_RESERVATION_DETAILS_INFO_KIOSK,
  ROUTE_MAKE_RESERVATION_ROOMS_FOUND,
} from 'constants/routes'
import { useNavigate } from 'react-router-dom'

const ViewMakeReservationDetailsKiosk = () => {
  const navigate = useNavigate()

  return (
    <KioskViewContainer
      pl={6}
      pr={6}
      pt={3}
    >
      <ReservationSteps step={RESERVATION_STEP_DETAILS} />
      <Common.Div
        ml={8}
        mr={8}
      >
        <Typography.H4
          mb={3}
          mt={5}
          align="center"
          secondary
        >
          You selected
        </Typography.H4>
        <RoomDetails />
        <BackNextButtonsKiosk
          handleBackButton={() => navigate(ROUTE_MAKE_RESERVATION_ROOMS_FOUND)}
          handleNextButton={() =>
            navigate(ROUTE_MAKE_RESERVATION_DETAILS_INFO_KIOSK)
          }
        />
      </Common.Div>
    </KioskViewContainer>
  )
}

export default ViewMakeReservationDetailsKiosk
