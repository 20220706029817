import thankYouBg from 'assets/bgs/thankYouBg.png'
import iconCards from 'assets/icons/iconCardsGrey.svg'
import iconCheckHeart from 'assets/icons/iconCheckHeart.svg'

import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import WideModal from 'components/_modals/WideModal'
import { ROUTE_HOME } from 'constants/routes'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ICommonModalProps } from 'types/common'

const ModalCheckOutSuccessDesc = () => {
  const { t } = useTranslation()
  return (
    <Common.Div
      flex="row"
      alignItems="center"
      justifyContent="center"
      h="170px"
      gap="32px"
    >
      <Common.SvgIcon
        src={iconCards}
        mb={4}
        w="62px"
        h="58px"
      />
      <Typography.Body2
        secondary
        mb={4}
        align="center"
      >
        {t('checkOut.success.desc')}
      </Typography.Body2>
    </Common.Div>
  )
}

const ModalCheckOutSuccess = ({ isOpen, setIsOpen }: ICommonModalProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        navigate(ROUTE_HOME)
      }, 4000)

      return () => clearTimeout(timer)
    }
  }, [isOpen])

  return (
    <WideModal
      variant="action"
      bgSrc={thankYouBg}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      bottomChildren={<ModalCheckOutSuccessDesc />}
      closeOnClickOutside={false}
    >
      <Common.Div
        flex="column"
        fullyCentered
      >
        <Common.SvgIcon
          src={iconCheckHeart}
          mb={4}
          w="62px"
          h="58px"
        />
        <Typography.Body2
          tertiary
          mb={2}
        >
          {t('checkOut.success.subHeader')}
        </Typography.Body2>
        <Typography.H2
          tertiary
          mb={4}
          align="center"
        >
          {t('checkOut.success.header')}
        </Typography.H2>{' '}
        <Typography.Body2
          tertiary
          mb={4}
          align="center"
        >
          {t('checkOut.success.desc')}
        </Typography.Body2>
      </Common.Div>
    </WideModal>
  )
}

export default ModalCheckOutSuccess
