import iconGallery from 'assets/icons/IconGallery.svg'
import * as Common from 'components/_common/Common'
import { Tooltip } from 'components/_common/Tooltip/Tooltip'
import * as Typography from 'components/_common/Typography'
import FacilitiesList, {
  FacilityIcon,
} from 'components/_views/Room/FacilitiesList'
import styled, { css } from 'styled-components'
import { IAmenity, IFacility } from 'types/room'
import {
  GalleryButton,
  RoomThumbnail,
} from 'views/desktopMobile/makeReservation/ViewMakeReservationRoomsFound.styled'

interface IRoomThumbnailsProps {
  isMobile: boolean
  setIsGalleryOpen: (val: boolean) => unknown
  photo: string
}

export const RoomNameDescription = ({
  isKiosk,
  name,
  description,
}: {
  isKiosk: boolean
  name: string
  description: string
}) => (
  <Common.Div
    flex={isKiosk ? 'row' : 'column'}
    gap="8px"
    alignItems={isKiosk ? 'center' : 'flex-start'}
  >
    <Typography.H4
      align="left"
      mb={2}
      secondary
    >
      {name}
    </Typography.H4>
    <Typography.Body1
      align="left"
      secondary
      mb={2}
    >
      {description}
    </Typography.Body1>
  </Common.Div>
)

export const FacilitiesDescription = ({
  amenities,
  facilities,
}: {
  amenities?: IAmenity[]
  facilities?: IFacility[]
}) => (
  <>
    {/* <FacilitiesList list={['air', 'wifi']} /> */}
    <Common.Div flex="row">
      {facilities?.map((facility: IFacility) => {
        return (
          <Tooltip text={facility.name}>
            <FacilityIcon
              src={facility.icon}
              key={facility.uuid}
            />
          </Tooltip>
        )
      })}
    </Common.Div>
    <Typography.Body4
      secondary
      mb={1}
      mt={1}
      align="left"
    >
      {Array.isArray(amenities)
        ? amenities.map((a: IAmenity) => a.name).join(' | ')
        : 'No amenities available'}
    </Typography.Body4>
  </>
)

export const RoomThumbnails = ({
  isMobile,
  setIsGalleryOpen,
  photo,
}: IRoomThumbnailsProps) => (
  <>
    <RoomThumbnail
      isMobile={isMobile}
      mr={4}
      src={photo}
      onClick={() => setIsGalleryOpen(true)}
    />
    <GalleryButton
      isMobile={isMobile}
      src={iconGallery}
      onClick={() => setIsGalleryOpen(true)}
    />
  </>
)

export const NightsAdultsText = styled(Typography.Body2)(
  ({ theme: { isKiosk } }) => css`
    font-size: 16px;

    ${isKiosk &&
    css`
      font-size: 11px;
    `}
  `
)

export const PriceText = styled(Typography.H3)(
  ({ theme: { isKiosk } }) => css`
    font-size: 32px;

    ${isKiosk &&
    css`
      font-size: 20px;
    `}
  `
)

export const TaxChargeText = styled(Typography.Body4)(
  ({ theme: { isKiosk } }) => css`
    font-size: 16px;

    ${isKiosk &&
    css`
      font-size: 10px;
    `}
  `
)
