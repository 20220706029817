import IconHuman from 'assets/icons/IconHuman.svg'
import IconIndicatorDown from 'assets/icons/IconIndicatorDown.svg'
import IconIndicatorUp from 'assets/icons/IconIndicatorUp.svg'

import Button from 'components/_common/Button'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import { TFormSearchRoomChosenInput } from 'components/_forms/FormSearchRoom'
import InputIncrementer from 'components/_inputs/InputIncrementer'
import { useOnClickOutside } from 'hooks/useClickOutside'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  selectFitCustomResolution,
  selectIsKiosk,
  setKioskInputOpened,
} from 'redux/slices/app'
import { selectRoomSearchParams } from 'redux/slices/room'
import styled, { css } from 'styled-components'
import { IFindRoomForm } from 'types/room'

interface IInputPersonCountProps {
  form: UseFormReturn<IFindRoomForm>
  selectedInput: TFormSearchRoomChosenInput
  setSelectedInput: Dispatch<SetStateAction<TFormSearchRoomChosenInput>>
}

const StyledDropdownModal = styled(Common.DropdownModal)<{
  shouldSqueeze?: boolean
}>(
  ({ shouldSqueeze }) => css`
    left: ${shouldSqueeze ? '0' : 'auto'};
    width: ${shouldSqueeze ? '100%' : 'auto'};
  `
)

const InputPersonCount = ({
  form,
  selectedInput,
  setSelectedInput,
}: IInputPersonCountProps) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 1266)
  )
  const shouldSqueezePicker = useAppSelector(state =>
    selectFitCustomResolution(state, 440)
  )
  const isKiosk = useAppSelector(selectIsKiosk)
  const dispatch = useAppDispatch()
  const searchData = useAppSelector(selectRoomSearchParams)

  useEffect(() => {
    form.setValue('adults', searchData.adults)
    form.setValue('children', searchData.children)
    form.setValue('rooms', searchData.rooms)
  }, [searchData])

  const isOpen = selectedInput === 'rooms'

  useOnClickOutside(ref, () => {
    setSelectedInput(null)
    if (isKiosk) {
      dispatch(setKioskInputOpened(false))
    }
  })

  const handleIconClick = () => {
    if (isOpen) {
      setSelectedInput(null)
      if (isKiosk) {
        dispatch(setKioskInputOpened(false))
      }
    } else {
      setSelectedInput('rooms')
      if (isKiosk) {
        dispatch(setKioskInputOpened(true))
      }
    }
  }

  const handleDoneButton = () => {
    setSelectedInput(null)
    dispatch(setKioskInputOpened(false))
  }

  const { watch, formState } = form
  const { adults, children, rooms } = watch()

  const adultsError = formState?.errors.adults as { message: string }
  const roomsError = formState?.errors.rooms as { message: string }

  return (
    <Common.Div
      ml={isMobile ? 0 : 2}
      mb={isMobile ? 2 : 0}
      flex="column"
      gap="8px"
    >
      <Common.DropdownWithIcon
        onClick={handleIconClick}
        isMobile={isMobile}
      >
        <Common.SvgIcon
          src={IconHuman}
          w="20px"
          h="20px"
          mr={1}
        />
        <Typography.Body2
          secondary
          mr={2}
        >{`${adults} adults | ${
          children ?? 'no'
        } children | ${rooms} room`}</Typography.Body2>
        <Common.SvgIcon
          src={isOpen ? IconIndicatorUp : IconIndicatorDown}
          w="10px"
          h="10px"
        />
      </Common.DropdownWithIcon>

      {isOpen && (
        <StyledDropdownModal
          ref={ref}
          shouldSqueeze={shouldSqueezePicker}
          w={isKiosk ? '296px' : 'auto'}
        >
          <Common.Div flex="column">
            <Common.Div
              flex="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography.Body1 secondary>
                {t('inputPerson.adults')}
              </Typography.Body1>
              <InputIncrementer
                name="adults"
                form={form}
              />
            </Common.Div>

            <Common.Div
              flex="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography.Body1
                secondary
                mr={2}
              >
                {t('inputPerson.children')}
              </Typography.Body1>
              <InputIncrementer
                name="children"
                form={form}
              />
            </Common.Div>
            <Common.Div
              flex="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography.Body1 secondary>
                {t('inputPerson.rooms')}
              </Typography.Body1>
              <InputIncrementer
                name="rooms"
                form={form}
              />
            </Common.Div>

            <Button onClick={handleDoneButton}>
              {t('inputPerson.button')}
            </Button>
          </Common.Div>
        </StyledDropdownModal>
      )}
      {adultsError && (
        <Typography.ErrorMessage>
          {adultsError?.message ?? ''}
        </Typography.ErrorMessage>
      )}
      {roomsError && (
        <Typography.ErrorMessage>
          {roomsError?.message ?? ''}
        </Typography.ErrorMessage>
      )}
    </Common.Div>
  )
}

export default InputPersonCount
