// Common
import * as yup from 'yup'

export const confirmReservationSchema = yup.object().shape({
  confirmationNumber: yup
    .string()
    .typeError('Provide a valid confirmation number')
    .required(),
})

export const searchSchema = yup.object().shape({
  resNumber: yup
    .string()
    .typeError('Reservation number must be a number')
    .required(),
})

export const findRoomSchema = yup.object().shape({
  adults: yup.number().required().min(1, 'Adult number is required'),
  rooms: yup.number().required().min(1, 'Rooms number is required'),
  stayDate: yup.object().shape({
    startDate: yup.string().required('Provide a stay date'),
    endDate: yup.string().required(),
  }),
})
