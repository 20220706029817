/* eslint-disable @typescript-eslint/ban-ts-comment */
import BackButton from 'components/_common/BackButton'
import * as Common from 'components/_common/Common'
import { KioskViewContainer } from 'components/_common/Common'
import LoaderWrapper from 'components/_common/LoaderWrapper'
import { Pagination } from 'components/_common/Pagination'
import * as Typography from 'components/_common/Typography'
import InputCheckbox from 'components/_inputs/InputCheckbox'
import ModalGallery from 'components/_modals/ModalGallery'
import { RoomRow } from 'components/_views/FindRoom/RoomRow'
import { SortBy } from 'components/_views/FindRoom/SortBy'
import { ROUTE_MAKE_RESERVATION } from 'constants/routes'
import { format } from 'date-fns'
import { useRooms } from 'logic/useRooms'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getDateFnsLocale } from 'utils/dates'
import {
  FullBorderSeparator,
  InfoBorderRectangle,
  PaginationContainer,
  VerticalBorderSeparator,
} from 'views/desktopMobile/makeReservation/ViewMakeReservationRoomsFound.styled'

const ViewMakeReservationRoomsFoundKiosk = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const formFindRoom = useForm()
  const [isGalleryOpen, setIsGalleryOpen] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const language: string = i18n.language
  const dateLocale = getDateFnsLocale(language)

  const {
    handleSort,
    sortedItems,
    sortOrder,
    isLoading,
    pagination,
    searchData,
  } = useRooms({
    page: currentPageNumber,
    perPage: 3,
  })

  const startDate = searchData?.stayDate?.startDate
  const endDate = searchData?.stayDate?.endDate
  const formattedStartDate =
    //@ts-ignore
    startDate instanceof Date
      ? format(startDate, 'EEE d MMM RRRR', {
          locale: dateLocale,
        })
      : startDate
  const formattedEndDate =
    //@ts-ignore
    endDate instanceof Date
      ? format(endDate, 'EEE d MMM RRRR', {
          locale: dateLocale,
        })
      : endDate

  return (
    <KioskViewContainer>
      <Typography.H4
        mt={3}
        mb={2}
        align="center"
        secondary
      >
        {t('roomsFound.header')}
      </Typography.H4>
      <InfoBorderRectangle>
        <Common.Div
          flex="row"
          gap="8px"
          ml={5}
          mr={5}
        >
          <Typography.Body1 secondary>
            {t('roomsFound.checkIn')}
          </Typography.Body1>
          <Typography.Body1 secondary>{formattedStartDate}</Typography.Body1>
        </Common.Div>
        <VerticalBorderSeparator />
        <Common.Div
          flex="row"
          gap="8px"
          ml={5}
          mr={5}
        >
          <Typography.Body1 secondary>
            {t('roomsFound.checkOut')}
          </Typography.Body1>
          <Typography.Body1 secondary>{formattedEndDate}</Typography.Body1>
        </Common.Div>
        <VerticalBorderSeparator />
        <Common.Div
          flex="row"
          ml={4}
          alignItems="center"
        >
          <InputCheckbox
            name="breakfast"
            form={formFindRoom}
            label={t('roomsFound.breakfastCheckbox')}
            secondaryLabel=" - 50 zl"
          />
          <Common.Div ml={4} />
          <InputCheckbox
            name="parking"
            form={formFindRoom}
            label={t('roomsFound.parkingCheckbox')}
            secondaryLabel=" - 30 zl/day"
          />
        </Common.Div>
      </InfoBorderRectangle>
      <FullBorderSeparator />
      <SortBy
        handleSort={handleSort}
        sortOrder={sortOrder}
      />
      <Common.Div
        h="472px"
        pl={5}
        pr={5}
        pt={4}
      >
        <LoaderWrapper isLoading={isLoading}>
          {sortedItems.map((data, index) => (
            <Fragment key={data.uuid}>
              <RoomRow
                setIsGalleryOpen={setIsGalleryOpen}
                data={data}
                searchData={searchData}
              />
              {index !== sortedItems.length - 1 && (
                <FullBorderSeparator mb={1} />
              )}
            </Fragment>
          ))}
        </LoaderWrapper>
      </Common.Div>
      <PaginationContainer>
        <FullBorderSeparator />
        <Common.Div
          flex="row"
          alignItems="center"
          pl={6}
          pr={6}
          justifyContent="space-between"
          h="48px"
          w="100%"
        >
          <BackButton onClick={() => navigate(ROUTE_MAKE_RESERVATION)} />
          <Common.Div>
            <Pagination
              totalPages={pagination.total_pages}
              currentPageNumber={currentPageNumber}
              setCurrentPageNumber={setCurrentPageNumber}
            />
          </Common.Div>
        </Common.Div>
      </PaginationContainer>

      <ModalGallery
        isOpen={isGalleryOpen}
        setIsOpen={setIsGalleryOpen}
      />
    </KioskViewContainer>
  )
}

export default ViewMakeReservationRoomsFoundKiosk
