// Common
import * as yup from 'yup'

export const errorMessages = {
  INVALID_EMAIL_FORMAT: 'Invalid e-mail address format',
  REQUIRED: 'This field is required',
  PRIVACY_TEXT: 'Selecting consent is required',
  INVALID_PHONE_FORMAT: 'Phone number is not valid',
  PHONE_REQUIRED: 'Phone is required',
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const phoneValidation = yup
  .string()
  .matches(phoneRegExp, errorMessages.INVALID_PHONE_FORMAT)
  .required(errorMessages.PHONE_REQUIRED)

export const emailValidation = yup
  .string()
  .email(errorMessages.INVALID_EMAIL_FORMAT)

  .required(errorMessages.REQUIRED)

// TODO: Add regex for validation
export const reservationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: emailValidation,
  phone: phoneValidation,
  country: yup.string().required('Country is required'),
})

export const reservationSchemaKiosk = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: emailValidation,
  phone: phoneValidation,
  country: yup.string().required('Country is required'),
  terms: yup.boolean().required('You need to accept the terms'),
})
