import { useAppSelector } from 'hooks/useRedux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { selectIsKiosk } from 'redux/slices/app'
import { MAPPED_ROUTES } from 'routers/RouterMaps'

const RouterApp = () => {
  const isKiosk = useAppSelector(selectIsKiosk)

  return (
    <BrowserRouter>
      <Routes>
        {MAPPED_ROUTES.map(
          ({ paths, components, layout: Layout, withBreadCrumbs }) => {
            return paths.map(path => {
              const Component = isKiosk ? components.kiosk : components.desktop

              return (
                <Route
                  path={path}
                  key={path}
                  element={
                    <Layout withBreadcrumbs={withBreadCrumbs}>
                      <Component />
                    </Layout>
                  }
                />
              )
            })
          }
        )}
      </Routes>
    </BrowserRouter>
  )
}

export default RouterApp
