import { yupResolver } from '@hookform/resolvers/yup'
import SearchButtonIcon from 'assets/icons/searchButton.svg'
import Button from 'components/_common/Button'
import * as Common from 'components/_common/Common'
import InputCheckbox from 'components/_inputs/InputCheckbox'
import InputDateRange from 'components/_inputs/InputDateRange'
import InputPersonCount from 'components/_inputs/InputPersonCount'
import { ROUTE_MAKE_RESERVATION_ROOMS_FOUND } from 'constants/routes'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { selectFitCustomResolution, selectIsKiosk } from 'redux/slices/app'
import { setSearchParams, selectRoomSearchParams } from 'redux/slices/room'
import styled, { css } from 'styled-components'
import { IFindRoomForm } from 'types/room'
import { countDaysBetweenDates } from 'utils/dates'
import { findRoomSchema } from 'utils/validation/search'

export type TFormSearchRoomChosenInput = 'date' | 'rooms' | null

interface IFormSearchRoomProps {
  withCheckbox?: boolean
}

const StretchedForm = styled.form(
  () => css`
    width: 100%;
  `
)

const SearchButton = styled(Button)(
  () => css`
    width: 50px;
    min-width: 50px;
    height: 50px;
    background: url(${SearchButtonIcon});
    background-position: center;
  `
)

const FormSearchRoom = ({ withCheckbox = false }: IFormSearchRoomProps) => {
  const isKiosk = useAppSelector(selectIsKiosk)
  const navigate = useNavigate()
  const searchData = useAppSelector(selectRoomSearchParams)
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 1266)
  )

  const formFindRoom = useForm<IFindRoomForm>({
    mode: 'onSubmit',
    defaultValues: {
      adults: searchData.adults || 1,
      children: searchData.children || 0,
      rooms: searchData.rooms || 1,
      stayDate: {
        startDate: searchData.stayDate?.startDate
          ? new Date(searchData.stayDate.startDate).toISOString().split('T')[0]
          : '',
        endDate: searchData.stayDate?.endDate
          ? new Date(searchData.stayDate.endDate).toISOString().split('T')[0]
          : '',
      },
    },
    resolver: yupResolver(findRoomSchema),
  })

  const [selectedInput, setSelectedInput] =
    useState<TFormSearchRoomChosenInput>(null)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleSearch = () => {
    const { adults, children, rooms, stayDate } = formFindRoom.watch()

    const startDate = new Date(stayDate.startDate)
    const endDate = new Date(stayDate.endDate)

    const nights = countDaysBetweenDates(startDate, endDate)

    dispatch(
      setSearchParams({
        adults,
        children,
        rooms,
        stayDate: {
          startDate: stayDate.startDate,
          endDate: stayDate.endDate,
          nights,
        },
      })
    )

    navigate(ROUTE_MAKE_RESERVATION_ROOMS_FOUND)
  }

  useEffect(() => {
    if (searchData.stayDate.startDate && searchData.stayDate.endDate) {
      formFindRoom.setValue('stayDate', {
        startDate: new Date(searchData.stayDate.startDate)
          .toISOString()
          .split('T')[0],
        endDate: new Date(searchData.stayDate.endDate)
          .toISOString()
          .split('T')[0],
      })
    }
  }, [searchData, formFindRoom])

  return (
    <Common.Div
      flex="row"
      w="100%"
    >
      <StretchedForm onSubmit={formFindRoom.handleSubmit(handleSearch)}>
        <Common.Div flex="column">
          <Common.Div
            flex={isKiosk || !isMobile ? 'row' : 'column'}
            w="100%"
            justifyContent="center"
            mb={withCheckbox ? 2 : 0}
            gap="16px"
          >
            <Common.Div
              flex={isKiosk || !isMobile ? 'row' : 'column'}
              gap="8px"
            >
              <InputDateRange
                form={formFindRoom}
                selectedInput={selectedInput}
                setSelectedInput={setSelectedInput}
              />
              <InputPersonCount
                form={formFindRoom}
                selectedInput={selectedInput}
                setSelectedInput={setSelectedInput}
              />
            </Common.Div>
            {isMobile && !isKiosk ? (
              <Button type="submit">{t('findTheRoom.button')}</Button>
            ) : (
              <SearchButton type="submit" />
            )}
          </Common.Div>
          {withCheckbox && (
            <Common.Div flex="row">
              <InputCheckbox
                name="breakfast"
                form={formFindRoom}
                label="Breakfast inclued"
                secondaryLabel=" - 50 zl"
              />
            </Common.Div>
          )}
        </Common.Div>
      </StretchedForm>
    </Common.Div>
  )
}

export default FormSearchRoom
