/* eslint-disable no-console */
import exampleRoom from 'assets/graphics/exampleRoom.png'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import FacilitiesList from 'components/_views/Room/FacilitiesList'
import { format } from 'date-fns'
import useCalculateNights from 'hooks/useCalculateNights'
import { useAppSelector } from 'hooks/useRedux'
import { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectFitCustomResolution } from 'redux/slices/app'
import { selectReservationDetails } from 'redux/slices/reservation/reservationSelectors'
import { selectRoomSearchParams, selectSelectedRoom } from 'redux/slices/room'
import { selectUserDetails } from 'redux/slices/user/userSelector'
import { getDateFnsLocale } from 'utils/dates'
import {
  BorderFormBox,
  RoomDetailsContainer,
  RoomThumbnail,
  VerticalBorder,
} from 'views/desktopMobile/ViewYourReservation.styled'

interface ISearchParams {
  stayDate?: {
    startDate: string
    endDate: string
    nights?: number
  }
  adults?: number
  children?: number
}

interface IReservationDetails {
  date_from?: string
  date_to?: string
  nights?: number
  number_of_adults?: number
  number_of_children?: number
  amount?: string
}

const VAT_RATE = 0.23

const calculateVAT = (price: number) => {
  return (price * VAT_RATE).toFixed(2)
}

const roomParams = (
  isMakeReservation: boolean,
  room: ISearchParams | IReservationDetails | null
) => {
  const { i18n } = useTranslation()
  const language: string = i18n.language
  const dateLocale = getDateFnsLocale(language)

  if (!room) return []

  const startDate = isMakeReservation
    ? (room as ISearchParams).stayDate?.startDate
    : (room as IReservationDetails).date_from
  const endDate = isMakeReservation
    ? (room as ISearchParams).stayDate?.endDate
    : (room as IReservationDetails).date_to

  const nights = useCalculateNights(startDate, endDate)

  return isMakeReservation
    ? [
        {
          firstParam: 'reservationDetails.checkIn',
          value: startDate
            ? format(new Date(startDate), 'EEE d MMM yyyy', {
                locale: dateLocale,
              })
            : '',
          secondValue: 'from 15:00',
        },
        {
          firstParam: 'reservationDetails.checkOut',
          value: endDate
            ? format(new Date(endDate), 'EEE d MMM yyyy', {
                locale: dateLocale,
              })
            : '',
          secondValue: 'until 12:00',
        },
        {
          firstParam: 'reservationDetails.totalLength',
          value: nights,
        },
        {
          firstParam: 'reservationDetails.noOfGuests',
          value:
            ((room as ISearchParams).adults ?? 0) +
            ((room as ISearchParams).children ?? 0),
        },
      ]
    : [
        {
          firstParam: 'reservationDetails.checkIn',
          value: startDate
            ? format(new Date(startDate), 'EEE d MMM yyyy', {
                locale: dateLocale,
              })
            : '',
          secondValue: 'from 15:00',
        },
        {
          firstParam: 'reservationDetails.checkOut',
          value: endDate
            ? format(new Date(endDate), 'EEE d MMM yyyy', {
                locale: dateLocale,
              })
            : '',
          secondValue: 'until 12:00',
        },
        {
          firstParam: 'reservationDetails.totalLength',
          value: nights,
        },
        {
          firstParam: 'reservationDetails.noOfGuests',
          value:
            ((room as IReservationDetails).number_of_adults ?? 0) +
            ((room as IReservationDetails).number_of_children ?? 0),
        },
      ]
}

export const RoomDetails = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation()
  const checkInReservationData = useSelector(selectReservationDetails)
  const searchParams = useAppSelector(selectRoomSearchParams)
  const selectedRoom = useAppSelector(selectSelectedRoom)
  const userDetails = useAppSelector(selectUserDetails)
  const { pathname } = useLocation()
  const isCheckout = pathname.includes('checkout')
  const isMakeReservation = pathname.includes('make-reservation')
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 700)
  )

  const roomPrice = (() => {
    if (selectedRoom) {
      return parseFloat(selectedRoom.price)
    }
    if (checkInReservationData) {
      return parseFloat(checkInReservationData.amount)
    }
    return 0
  })()

  const vatAmount = calculateVAT(roomPrice)

  return (
    <RoomDetailsContainer>
      <BorderFormBox>
        <Common.Div
          flex="column"
          h="100%"
          justifyContent="center"
        >
          {!isMakeReservation && (
            <>
              <Common.Div
                flex="row"
                justifyContent="space-between"
              >
                <Typography.H2 secondary>
                  {`${userDetails?.first_name ?? ''} ${
                    userDetails?.last_name ?? ''
                  }`}
                </Typography.H2>
                <Common.Div
                  flex="column"
                  gap="8px"
                  mr={15}
                >
                  <Typography.Body1
                    s={16}
                    secondary
                  >
                    {t('confirmReservation.yourReservationNumber')}
                  </Typography.Body1>
                  <Typography.Body1
                    s={20}
                    secondary
                  >
                    {checkInReservationData?.reservation_code}
                  </Typography.Body1>
                </Common.Div>
              </Common.Div>
              <Common.BarBorder
                mt={2}
                mb={2}
              />
            </>
          )}
          <Common.Div
            flex="row"
            w="100%"
          >
            <RoomThumbnail src={selectedRoom?.photo || exampleRoom} />
            <Common.Div flex="column">
              <Common.Div
                flex="row"
                mb={1}
              >
                <Typography.Body1
                  mr={1}
                  secondary
                >
                  {selectedRoom?.name}
                </Typography.Body1>
                <Typography.Body1 secondary>
                  {selectedRoom?.description}
                </Typography.Body1>
              </Common.Div>
              <FacilitiesList
                list={['air', 'wifi']}
                iconsOnly
              />
            </Common.Div>
          </Common.Div>
          <Common.BarBorder
            mt={2}
            mb={2}
          />
          <Common.Div
            flex="row"
            w="100%"
            alignItems="center"
          >
            {roomParams(
              isMakeReservation,
              isMakeReservation ? searchParams : checkInReservationData
            ).map((rp, index) => (
              <Fragment key={rp.firstParam}>
                <Common.Div
                  flex="column"
                  mb={2}
                  key={rp.firstParam}
                >
                  <Typography.Body2
                    secondary
                    s={13}
                    bold
                    mb={0.5}
                  >
                    {t(rp.firstParam)}
                  </Typography.Body2>
                  <Typography.Body1
                    s={16}
                    mb={0.5}
                    secondary
                  >
                    {rp.value}
                  </Typography.Body1>
                  {rp.secondValue && (
                    <Typography.Body2
                      secondary
                      s={13}
                    >
                      {rp.secondValue}
                    </Typography.Body2>
                  )}
                </Common.Div>
                {index !== 3 && (
                  <VerticalBorder
                    ml={4}
                    mr={4}
                  />
                )}
              </Fragment>
            ))}
          </Common.Div>
        </Common.Div>

        {!isCheckout && (
          <>
            <Common.BarBorder
              mt={2}
              mb={2}
            />
            <Common.Div
              flex="row"
              w="100%"
              alignItems="center"
            >
              <Common.Div
                flex="column"
                w="100%"
              >
                <Common.Div
                  flex="row"
                  justifyContent="space-between"
                >
                  <Typography.Body1
                    bold
                    secondary
                  >
                    Your price summary
                  </Typography.Body1>
                  {!isMobile && (
                    <Typography.Body1
                      bold
                      secondary
                    >
                      Total
                    </Typography.Body1>
                  )}
                </Common.Div>
                {isMobile && (
                  <Typography.Body1
                    mt={2}
                    secondary
                  >
                    Total
                  </Typography.Body1>
                )}
                <Common.Div
                  flex="column"
                  w="100%"
                  alignItems={isMobile ? 'flex-start' : 'flex-end'}
                >
                  <Typography.H3 secondary>{roomPrice} zł</Typography.H3>
                  <Typography.Body2 secondary>
                    includes {vatAmount} zł in taxes and charges
                  </Typography.Body2>
                </Common.Div>
              </Common.Div>
            </Common.Div>
          </>
        )}
      </BorderFormBox>
      {children}
    </RoomDetailsContainer>
  )
}
