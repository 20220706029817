import { useState } from 'react'
import {
  TooltipBox,
  TooltipPosition,
  TooltipText,
  TooltipTitle,
  TooltipTrigger,
  TooltipWrapper,
} from './Tooltip.styled'

export interface IProps {
  children: any
  position?: string
  text?: string
  title?: string
}

export const Tooltip = ({ children, position, text, title }: IProps) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseOver = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  return (
    <TooltipWrapper>
      <TooltipTrigger
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </TooltipTrigger>
      {showTooltip && (
        <TooltipBox>
          {title && <TooltipTitle>{title}</TooltipTitle>}
          {position && <TooltipPosition>{position}</TooltipPosition>}
          {text && <TooltipText>{text}</TooltipText>}
        </TooltipBox>
      )}
    </TooltipWrapper>
  )
}
