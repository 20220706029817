import invoicePrintingBg from 'assets/bgs/invoicePrintingBg.png'
import iconPrinter from 'assets/icons/iconPrinter.svg'

import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import WideModal from 'components/_modals/WideModal'
import { ROUTE_MAKE_RESERVATION_THANK_YOU } from 'constants/routes'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'
import { ICommonModalProps } from 'types/common'

const ModalInvoicePrinting = ({ isOpen, setIsOpen }: ICommonModalProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        navigate(ROUTE_MAKE_RESERVATION_THANK_YOU)
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [isOpen])

  return (
    <WideModal
      variant="full"
      bgSrc={invoicePrintingBg}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      closeOnClickOutside={false}
    >
      <Common.Div
        flex="column"
        fullyCentered
      >
        <Common.SvgIcon
          src={iconPrinter}
          mb={2}
        />
        <Typography.H2
          tertiary
          mb={2}
        >
          {t('invoice.modal.header')}
        </Typography.H2>
        <Typography.Body2 tertiary>{t('invoice.modal.desc')}</Typography.Body2>
      </Common.Div>
    </WideModal>
  )
}

export default ModalInvoicePrinting
