import Modal from 'components/_modals/Modal'
import React, { useEffect, useState } from 'react'
import room1 from 'assets/graphics/room/255683470.png'
import room2 from 'assets/graphics/room/255684025.png'
import room3 from 'assets/graphics/room/255684822.png'
import room4 from 'assets/graphics/room/255685967.png'
import * as Common from 'components/_common/Common'
import ImageGallery from 'react-image-gallery'
import styled, { css } from 'styled-components'

import { ICommonModalProps } from 'types/common'

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

export const GalleryWrapper = styled(Common.Div)(
  ({ theme: { colors, spacing } }) => css``
)

const ModalGallery = ({ isOpen, setIsOpen }: ICommonModalProps) => {
  const [isZoomView, setIsZoomView] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setIsZoomView(false)
    }
  }, [isOpen])

  const images = [
    {
      original: room1,
      thumbnail: room1,
    },
    {
      original: room2,
      thumbnail: room2,
    },
  ]

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Deluxe Room"
    >
      {isZoomView ? (
        <GalleryWrapper>
          <ImageGallery items={images} />
        </GalleryWrapper>
      ) : (
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry>
            <Common.SvgIcon
              m={1}
              src={room1}
              onClick={() => setIsZoomView(true)}
            />
            <Common.SvgIcon
              m={1}
              src={room2}
              onClick={() => setIsZoomView(true)}
            />
            <Common.SvgIcon
              m={1}
              src={room3}
              onClick={() => setIsZoomView(true)}
            />
            <Common.SvgIcon
              m={1}
              src={room4}
              onClick={() => setIsZoomView(true)}
            />
          </Masonry>
        </ResponsiveMasonry>
      )}
    </Modal>
  )
}

export default ModalGallery
