import * as Common from 'components/_common/Common'
import InputCheckbox from 'components/_inputs/InputCheckbox'
import InputPhone from 'components/_inputs/InputPhone'
import InputSelect from 'components/_inputs/InputSelect'
import InputText from 'components/_inputs/InputText'
import { countryOptions } from 'constants/countries'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IYourInfoFormKiosk } from 'types/room'
import { BorderFormBox } from 'views/desktopMobile/ViewYourReservation.styled'

const FormYourInfoKiosk = ({
  form,
}: {
  form: UseFormReturn<IYourInfoFormKiosk>
}) => {
  const { t } = useTranslation()
  const isError = Object.keys(form.formState.errors).length !== 0

  return (
    <BorderFormBox
      h="352px"
      w="754px"
    >
      <Common.Div
        mb={2}
        flex="column"
      >
        <Common.Div
          flex="row"
          gap="16px"
          mb={isError ? 0.5 : 3}
        >
          <InputText
            label="First Name"
            name="firstName"
            form={form}
            mandatory
          />
          <InputText
            label="Last Name"
            name="lastName"
            form={form}
            mandatory
          />
        </Common.Div>
        <Common.Div
          flex="row"
          gap="16px"
          mb={isError ? 0.5 : 3}
        >
          <InputSelect
            label="Country/region"
            name="country"
            form={form}
            options={countryOptions}
            mandatory
          />
        </Common.Div>
        <Common.Div
          flex="row"
          gap="16px"
          mb={isError ? 0.5 : 2}
        >
          <Common.Div w="100%">
            <InputPhone
              label="Telephone (mobile number preferred)"
              name="phone"
              form={form}
              mandatory
            />
          </Common.Div>
          <InputText
            label="E-mail"
            name="email"
            form={form}
            isMobile={false}
            mandatory
          />
        </Common.Div>
        <InputCheckbox
          name="terms"
          form={form}
          label={t('reservationDetails.form.terms')}
        />
      </Common.Div>
    </BorderFormBox>
  )
}

export default FormYourInfoKiosk
