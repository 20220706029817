import thankYouBigBg from 'assets/bgs/thankYouBigBg.png'

import iconBedWhite from 'assets/icons/iconBedWhite.svg'
import Button from 'components/_common/Button'
import * as Common from 'components/_common/Common'
import { ICustomStyledProps } from 'components/_common/commonStyles'

import * as Typography from 'components/_common/Typography'
import { ROUTE_HOME } from 'constants/routes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const ViewThankYouContainer = styled(Common.Div)<ICustomStyledProps>(
  () => css`
    background: url(${thankYouBigBg}) no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 170px);
  `
)

const ViewThankYou = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <ViewThankYouContainer
      fullyCentered
      p={4}
    >
      <Common.SvgIcon
        src={iconBedWhite}
        mb={4}
        w="62px"
        h="58px"
      />

      <Typography.H3
        mb={2}
        tertiary
        align="center"
      >
        {t('thankYou.header')}
      </Typography.H3>
      <Typography.Body2
        mb={4}
        tertiary
      >
        {t('thankYou.desc')}
      </Typography.Body2>
      <Button
        onClick={() => navigate(ROUTE_HOME)}
        variant="secondary"
      >
        {t('thankYou.doneButton')}
      </Button>
    </ViewThankYouContainer>
  )
}

export default ViewThankYou
