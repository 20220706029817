import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import {
  customProps,
  ICustomStyledProps,
  TButtonClickHandler,
} from 'components/_common/commonStyles'

const StyledButton = styled.button<
  ICustomStyledProps & { variant?: 'primary' | 'secondary' }
>(
  ({ theme: { colors, params, isKiosk }, variant, isMobile }) => css`
    ${customProps};
    outline: none;
    border: ${variant === 'primary' ? 'none' : `1px solid ${colors.primary}`};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 20px;
    height: 45px;
    min-width: ${isMobile ? '100%' : '215px'};
    transition: 0.25s;
    background: ${variant === 'primary' ? colors.primary : colors.secondary};
    color: ${variant === 'primary' ? colors.secondary : colors.primary};
    font-size: min(20px, 5vw);
    line-height: 26px;
    border-radius: ${params.buttonBorderRadius};

    ${isKiosk &&
    css`
      min-width: 175px;
      text-transform: uppercase;
      font-size: 16px;
    `};

    @media (max-width: 800px) {
      min-width: auto;
    }
  `
)

interface IButtonProps extends ICustomStyledProps {
  type?: 'submit' | 'button' | 'reset'
  onClick?: TButtonClickHandler
  children?: ReactNode
  className?: string
  disabled?: boolean
  variant?: 'primary' | 'secondary'
}

const Button = ({
  className = '',
  onClick,
  children,
  type,
  variant = 'primary',
  disabled = false,
  ...rest
}: IButtonProps) => (
  <StyledButton
    onClick={onClick}
    className={className}
    type={type}
    variant={variant}
    disabled={disabled}
    {...rest}
  >
    {children}
  </StyledButton>
)

export default Button
