import API from 'api/client'
import { IRequestResponse } from 'types/api'
import { IRoom, IRoomsMeta } from 'types/room'

export const getRooms = async (meta: IRoomsMeta) => {
  const { data }: { data: IRequestResponse<IRoom> } = await API.get(
    `room${meta.page ? `?page=${meta.page}` : ''}
    ${meta.perPage ? `&per_page=${meta.perPage}` : ''}
    `
  )
  return data
}
