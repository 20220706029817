import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import { fetchRooms, selectRoom } from 'redux/slices/room/roomActions'
import { IRequestResponse } from 'types/api'
import {
  IReservationDetails,
  IRoom,
  IRoomState,
  ISearchParams,
} from 'types/room'

export const reducers = {
  setSearchParams: (
    state: IRoomState,
    action: PayloadAction<ISearchParams>
  ) => {
    state.searchParams = action.payload
  },
  setReservationData: (
    state: IRoomState,
    action: PayloadAction<IReservationDetails>
  ) => {
    state.reservationData = action.payload
  },
}

export const extraReducers = (builder: ActionReducerMapBuilder<IRoomState>) => {
  builder.addCase(fetchRooms.pending, state => {
    state.rooms.isLoading = true
  })
  builder.addCase(
    fetchRooms.fulfilled,
    (state, action: PayloadAction<IRequestResponse<IRoom>>) => {
      state.rooms.data = action.payload.data
      state.rooms.meta = action.payload.meta
      state.rooms.isLoading = false
    }
  )
  builder.addCase(fetchRooms.rejected, state => {
    state.rooms.isLoading = false
  })
  builder.addCase(selectRoom, (state, action: PayloadAction<IRoom>) => {
    state.selectedRoom = action.payload
  })
}
