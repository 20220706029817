import stepGraphics from 'assets/graphics/stepCheckinSummary.svg'
import BackButton from 'components/_common/BackButton'
import Button from 'components/_common/Button'
import * as Common from 'components/_common/Common'
import NextButton from 'components/_common/NextButton'
import * as Typography from 'components/_common/Typography'
import AdditionalServices from 'components/_views/CheckInOut/AdditionalServices'
import { StyledStepGraphics } from 'components/_views/ReservationDetailsSteps/ReservationSteps'
import { RoomDetails } from 'components/_views/Room/RoomDetails'
import {
  ROUTE_CHECKIN_FIND_RESERVATION,
  ROUTE_MAKE_RESERVATION,
  ROUTE_MAKE_RESERVATION_CARD_PRINTING,
} from 'constants/routes'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { checkIn } from 'redux/slices/booking/bookingSlice'
import { selectReservationDetails } from 'redux/slices/reservation/reservationSelectors'
import { AppDispatch } from 'redux/store'
import { ViewYourReservationContainer } from 'views/desktopMobile/ViewYourReservation.styled'

const ViewCheckInOutReservationDetails = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const isCheckout = pathname.includes('checkout')
  const [showAdditionalServices, setShowAdditionalServices] = useState(false)
  const bookingDetails = useSelector(selectReservationDetails)

  const handleBackButton = () => {
    navigate(ROUTE_CHECKIN_FIND_RESERVATION)
  }

  const handleNextButton = async () => {
    if (bookingDetails?.uuid) {
      const resultAction = await dispatch(checkIn(bookingDetails.uuid))
      if (checkIn.fulfilled.match(resultAction)) {
        navigate(ROUTE_MAKE_RESERVATION_CARD_PRINTING)
      }
    } else {
      navigate(ROUTE_MAKE_RESERVATION_CARD_PRINTING)
    }
  }

  return (
    <ViewYourReservationContainer isMobile>
      <Common.Div
        flex="column"
        alignItems="center"
      >
        <StyledStepGraphics
          src={stepGraphics}
          mb={4}
          isMobile
        />

        {showAdditionalServices ? (
          <AdditionalServices />
        ) : (
          <>
            <Typography.H4
              mb={2}
              secondary
            >
              Your reservation details
            </Typography.H4>
            <RoomDetails>
              {isCheckout ? (
                <Common.Div
                  w="754px"
                  mt={2}
                >
                  <Common.Div
                    flex="row"
                    justifyContent="flex-end"
                    gap="16px"
                  >
                    <Button
                      onClick={() => navigate(ROUTE_MAKE_RESERVATION)}
                      variant="secondary"
                    >
                      {t('reservationDetails.buttonExtend')}
                    </Button>
                    <Button onClick={() => setShowAdditionalServices(true)}>
                      {t('reservationDetails.buttonCheckout')}
                    </Button>
                  </Common.Div>
                </Common.Div>
              ) : (
                <Common.Div
                  flex="row"
                  justifyContent="space-between"
                  mt={2}
                  w="100%"
                >
                  <BackButton onClick={handleBackButton} />
                  <NextButton onClick={handleNextButton} />
                </Common.Div>
              )}
            </RoomDetails>
          </>
        )}
      </Common.Div>
    </ViewYourReservationContainer>
  )
}

export default ViewCheckInOutReservationDetails
