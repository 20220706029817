import thankYouBg from 'assets/bgs/thankYouBg.png'
import creditCardCheck from 'assets/icons/iconCreditCardCheck.svg'
import doorIcon from 'assets/icons/iconDoorArrow.svg'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import WideModal from 'components/_modals/WideModal'
import { ROUTE_HOME } from 'constants/routes'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetBookingStatus } from 'redux/slices/booking/bookingSlice'
import { ICommonModalProps } from 'types/common'

const ModalThankYouDesc = () => {
  return (
    <Common.Div
      flex="row"
      alignItems="center"
      h="130px"
    >
      <Common.Div
        flex="row"
        gap="32px"
      >
        <Common.SvgIcon
          src={doorIcon}
          ml={4}
        />
        <Typography.Body4
          s={14}
          secondary
          mt={1}
        >
          <Trans i18nKey="thankYou.modal.actionText" />
        </Typography.Body4>
      </Common.Div>
    </Common.Div>
  )
}

const ModalThankYou = ({ isOpen, setIsOpen }: ICommonModalProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        dispatch(resetBookingStatus())
        navigate(ROUTE_HOME)
      }, 4000)

      return () => clearTimeout(timer)
    }
  }, [isOpen, dispatch, navigate])

  return (
    <WideModal
      variant="action"
      bgSrc={thankYouBg}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      bottomChildren={<ModalThankYouDesc />}
      closeOnClickOutside={false}
    >
      <Common.Div
        flex="column"
        fullyCentered
      >
        <Common.SvgIcon
          src={creditCardCheck}
          mb={2}
        />
        <Typography.Body2
          mb={2}
          tertiary
        >
          {t('thankYou.modal.preHeader')}
        </Typography.Body2>

        <Typography.H2
          tertiary
          mb={2}
        >
          {t('thankYou.modal.header')}
        </Typography.H2>
        <Typography.Body2 tertiary>{t('thankYou.modal.desc')}</Typography.Body2>
      </Common.Div>
    </WideModal>
  )
}

export default ModalThankYou
