// React
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form'
import styled, { css } from 'styled-components'

import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import IconPlus from 'assets/icons/iconPlus.svg'
import IconMinus from 'assets/icons/iconMinus.svg'
import Button from 'components/_common/Button'

interface IInputSelectProps<T extends FieldValues> {
  name: Path<T>
  form: UseFormReturn<T>
}

export const Container = styled(Common.Div)(
  ({ theme: { colors, spacing } }) => css`
    border: 1px solid ${colors.border};
    padding: ${spacing(1)};
    flex: initial;
    align-items: center;
    border-radius: 50px;
  `
)

export const IncrementButton = styled(Button)(
  ({ theme: { isKiosk } }) => css`
    border-radius: 50%;
    min-width: 0;
    min-height: 0;
    padding: 0;
    width: ${isKiosk ? '40px' : '48px'};
    height: ${isKiosk ? '40px' : '48px'};
  `
)

const InputIncrementer = <T extends FieldValues>({
  form,
  name,
}: IInputSelectProps<T>) => {
  const { control } = form

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value: formValue } }) => (
        <Container flex="row">
          <IncrementButton
            type="button"
            onClick={() => {
              onChange(formValue - 1)
            }}
            disabled={formValue === 0}
            mr={2}
          >
            <Common.SvgIcon src={IconMinus} />
          </IncrementButton>
          <Typography.Body1
            secondary
            bold
            mr={2}
          >
            {formValue}
          </Typography.Body1>
          <IncrementButton
            type="button"
            onClick={() => {
              onChange(Number(formValue) + 1)
            }}
          >
            <Common.SvgIcon src={IconPlus} />
          </IncrementButton>
        </Container>
      )}
    />
  )
}

export default InputIncrementer
