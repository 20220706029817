import * as Common from 'components/_common/Common'
import {
  customProps,
  ICustomStyledProps,
} from 'components/_common/commonStyles'
import * as Typography from 'components/_common/Typography'
import { MandatoryStar } from 'components/_common/Typography'
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form'
import styled, { css } from 'styled-components'

interface IInputTextProps<T extends FieldValues> {
  name: Path<T>
  label?: string
  disabled?: boolean
  form: UseFormReturn<T>
  type?: 'text' | 'number'
  mandatory?: boolean
  secondary?: boolean
}

export const StyledTextField = styled.input<{
  isError: boolean
  secondary?: boolean
}>(
  ({ theme: { colors, spacing, params, isKiosk }, isError, secondary }) => css`
    ${customProps};
    height: ${isKiosk ? '45px' : '50px'};
    padding: ${spacing(1)};
    border: 1px solid ${isError ? colors.error : colors.textSecondary};
    color: ${colors.textSecondary};
    width: 100%;
    border-radius: ${params.buttonBorderRadius};

    ${secondary &&
    css`
      background: none;
    `}
  `
)

const InputText = <T extends FieldValues>({
  name,
  form,
  label = '',
  type = 'text',
  disabled = false,
  mandatory = false,
  secondary = false,
  ...rest
}: IInputTextProps<T> & ICustomStyledProps) => {
  const { control } = form

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        const error = errors[name] as { message: string }
        return (
          <Common.Div
            flex="column"
            w="100%"
            {...rest}
          >
            {label && (
              <Common.Label secondary>
                {label}
                <MandatoryStar>{mandatory ? '*' : ''}</MandatoryStar>
              </Common.Label>
            )}
            <StyledTextField
              name={name}
              type={type}
              isError={!!error}
              disabled={disabled}
              onChange={onChange}
              value={value ?? ''}
              secondary={secondary}
            />
            {error && (
              <Typography.ErrorMessage>
                {error?.message ?? ''}
              </Typography.ErrorMessage>
            )}
          </Common.Div>
        )
      }}
    />
  )
}

export default InputText
