import * as Common from 'components/_common/Common'
import { useOnClickOutside } from 'hooks/useClickOutside'
import { useAppSelector } from 'hooks/useRedux'
import React, { ReactNode, useRef } from 'react'
import { createPortal } from 'react-dom'
import { selectFitCustomResolution } from 'redux/slices/app'
import styled, { css } from 'styled-components'
import { ICommonModalProps } from 'types/common'

interface IModalLargeProps extends ICommonModalProps {
  children: ReactNode
  className?: string
  closeOnClickOutside?: boolean
  variant: 'full' | 'action'
  bottomChildren?: any
  bgSrc: string
}

export const ModalWideBody = styled(Common.Div)(
  ({ theme: { colors, isKiosk } }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: ${colors.modalBackground};
    padding: 12px;
    min-width: 50vw;
    min-height: 50vh;
    max-height: 90vh;
    border: none;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    overflow: auto;
    z-index: 9999;
    width: 924px;
    height: 546px;

    &::-webkit-scrollbar {
      /* Hide the scrollbar */
      display: none;
    }

    /* ${isKiosk &&
    css`
      border-radius: 0;
      top: 111px;
      left: 50px;

      transform: translate(0, 0);
    `} */
  `
)

type BackgroundProps = { bgSrc: string; variant: 'full' | 'action' }

export const Background = styled(Common.Div)<BackgroundProps>(
  ({ theme: { isKiosk }, bgSrc, variant }) => css`
    width: 100%;
    height: ${variant === 'full' ? '100%' : '399px'};
    background: url(${bgSrc})};
  `
)

export const ModalOverlay = styled.div(
  ({ theme: { colors } }) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${colors.modalOverlay};
    z-index: 999;
  `
)

const ModalLarge = ({
  isOpen,
  setIsOpen,
  children,
  className,
  closeOnClickOutside = true,
  variant = 'full',
  bottomChildren,
  bgSrc,
}: IModalLargeProps) => {
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 926)
  )

  const modalRef = useRef(null)

  if (closeOnClickOutside) {
    useOnClickOutside(modalRef, () => {
      setIsOpen(false)
    })
  }

  return isOpen
    ? createPortal(
        <>
          <ModalOverlay />
          <ModalWideBody
            ref={modalRef}
            className={className}
            isMobile={isMobile}
          >
            {variant === 'full' && (
              <Background
                bgSrc={bgSrc}
                variant="full"
              >
                {children}
              </Background>
            )}
            {variant === 'action' && (
              <>
                <Background
                  bgSrc={bgSrc}
                  variant="action"
                >
                  {children}
                </Background>
                {bottomChildren}
              </>
            )}
          </ModalWideBody>
        </>,
        document.body
      )
    : null
}

export default ModalLarge
