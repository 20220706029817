import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import apiClient from 'api/client'
import { AxiosError } from 'axios'
import { IReservationDetails } from 'types/room'

interface ReservationResponse {
  data: IReservationDetails
}

interface CardMoveResponse {
  success: boolean
  message: string
}

interface ReservationError {
  message: string
}

interface ReservationState {
  fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
  verifyStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
  reservationDetails: IReservationDetails | null
}

const initialState: ReservationState = {
  fetchStatus: 'idle',
  verifyStatus: 'idle',
  error: null,
  reservationDetails: null,
}

export const fetchReservationDetails = createAsyncThunk<
  ReservationResponse,
  string,
  { rejectValue: ReservationError }
>(
  'reservation/fetchReservationDetails',
  async (reservationNumber, { rejectWithValue }) => {
    try {
      const response = await apiClient.get<ReservationResponse>(
        `/reservation/${reservationNumber}`
      )
      return response.data
    } catch (err) {
      const error: AxiosError<ReservationError> =
        err as AxiosError<ReservationError>
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const verifyReservation = createAsyncThunk<
  ReservationResponse,
  { uuid: string; code: string },
  { rejectValue: ReservationError }
>(
  'reservation/verifyReservation',
  async ({ uuid, code }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post<ReservationResponse>(
        `/reservation/${uuid}/verify`,
        { code }
      )
      return response.data
    } catch (err) {
      const error: AxiosError<ReservationError> =
        err as AxiosError<ReservationError>
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const moveToGate = createAsyncThunk<
  CardMoveResponse,
  void,
  { rejectValue: ReservationError }
>('reservation/moveToGate', async (_, { rejectWithValue }) => {
  try {
    const response = await apiClient.get<CardMoveResponse>(`/card/move-to-gate`)
    return response.data
  } catch (err) {
    const error: AxiosError<ReservationError> =
      err as AxiosError<ReservationError>
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    resetReservationStatus(state: ReservationState) {
      state.fetchStatus = 'idle'
      state.verifyStatus = 'idle'
      state.error = null
      state.reservationDetails = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchReservationDetails.pending, (state: ReservationState) => {
        state.fetchStatus = 'loading'
        state.error = null
      })
      .addCase(
        fetchReservationDetails.fulfilled,
        (
          state: ReservationState,
          action: PayloadAction<ReservationResponse>
        ) => {
          state.fetchStatus = 'succeeded'
          state.reservationDetails = action.payload.data
        }
      )
      .addCase(
        fetchReservationDetails.rejected,
        (
          state: ReservationState,
          action: PayloadAction<ReservationError | undefined>
        ) => {
          state.fetchStatus = 'failed'
          state.error = action.payload?.message || null
        }
      )
      .addCase(verifyReservation.pending, (state: ReservationState) => {
        state.verifyStatus = 'loading'
        state.error = null
      })
      .addCase(
        verifyReservation.fulfilled,
        (
          state: ReservationState
          // action: PayloadAction<ReservationResponse>
        ) => {
          state.verifyStatus = 'succeeded'
        }
      )
      .addCase(
        verifyReservation.rejected,
        (
          state: ReservationState,
          action: PayloadAction<ReservationError | undefined>
        ) => {
          state.verifyStatus = 'failed'
          state.error = action.payload?.message || null
        }
      )
      .addCase(moveToGate.pending, (state: ReservationState) => {
        state.fetchStatus = 'loading'
        state.error = null
      })
      .addCase(moveToGate.fulfilled, (state: ReservationState) => {
        state.fetchStatus = 'succeeded'
      })
      .addCase(
        moveToGate.rejected,
        (
          state: ReservationState,
          action: PayloadAction<ReservationError | undefined>
        ) => {
          state.fetchStatus = 'failed'
          state.error = action.payload?.message || null
        }
      )
  },
})

export const { resetReservationStatus } = reservationSlice.actions
export default reservationSlice.reducer
