import BackButton from 'components/_common/BackButton'
import * as Common from 'components/_common/Common'
import LoaderWrapper from 'components/_common/LoaderWrapper'
import { Pagination } from 'components/_common/Pagination'
import * as Typography from 'components/_common/Typography'
import FormSearchRoom from 'components/_forms/FormSearchRoom'
import ModalGallery from 'components/_modals/ModalGallery'
import { RoomRow } from 'components/_views/FindRoom/RoomRow'
import { SortBy } from 'components/_views/FindRoom/SortBy'
import { ROUTE_MAKE_RESERVATION } from 'constants/routes'
import { useAppSelector } from 'hooks/useRedux'
import { useRooms } from 'logic/useRooms'
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { selectFitCustomResolution } from 'redux/slices/app'
import {
  FullBorderSeparator,
  PaginationContainer,
} from 'views/desktopMobile/makeReservation/ViewMakeReservationRoomsFound.styled'

const ViewMakeReservationRoomsFound = () => {
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 1266)
  )
  const arePaddingsShrunk = useAppSelector(state =>
    selectFitCustomResolution(state, 526)
  )
  const navigate = useNavigate()

  const [currentPageNumber, setCurrentPageNumber] = useState(1)

  const [isGalleryOpen, setIsGalleryOpen] = useState(false)
  const {
    handleSort,
    sortedItems,
    sortOrder,
    isLoading,
    pagination,
    searchData,
  } = useRooms({
    page: currentPageNumber,
    perPage: 20,
  })

  return (
    <Common.Div
      flex="column"
      px={arePaddingsShrunk ? 3 : 12}
      py={isMobile ? 6 : 11.75}
    >
      <Common.Div
        flex={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Typography.H2
          mb={4}
          secondary
        >
          Rooms found{' '}
        </Typography.H2>
        <Common.Div
          flex="column"
          alignItems="flex-end"
        >
          <FormSearchRoom withCheckbox />
        </Common.Div>
      </Common.Div>
      <SortBy
        handleSort={handleSort}
        sortOrder={sortOrder}
      />
      <Common.BarBorder
        mb={2}
        mt={2}
      />
      <LoaderWrapper isLoading={isLoading}>
        {sortedItems.map((r, index) => (
          <Fragment key={r.uuid}>
            <RoomRow
              setIsGalleryOpen={setIsGalleryOpen}
              data={r}
              searchData={searchData}
            />
            {index !== sortedItems.length - 1 && <FullBorderSeparator mb={1} />}
          </Fragment>
        ))}
        <PaginationContainer>
          <FullBorderSeparator />
          <Common.Div
            flex="row"
            alignItems="center"
            pl={6}
            pr={6}
            justifyContent="space-between"
            h="48px"
            w="100%"
          >
            <BackButton onClick={() => navigate(ROUTE_MAKE_RESERVATION)} />
            <Common.Div>
              <Pagination
                totalPages={pagination.total_pages}
                currentPageNumber={currentPageNumber}
                setCurrentPageNumber={setCurrentPageNumber}
              />
            </Common.Div>
          </Common.Div>
        </PaginationContainer>
      </LoaderWrapper>

      <ModalGallery
        isOpen={isGalleryOpen}
        setIsOpen={setIsGalleryOpen}
      />
    </Common.Div>
  )
}

export default ViewMakeReservationRoomsFound
