import cardChip from 'assets/graphics/cardChip.png'
import HotelLogo from 'assets/graphics/hotel_logo.png'
import * as Common from 'components/_common/Common'
import { ICustomStyledProps } from 'components/_common/commonStyles'
import * as Typography from 'components/_common/Typography'
import React from 'react'
import styled, { css } from 'styled-components'

export const RoomCardContainer = styled(Common.Card)<ICustomStyledProps>(
  ({ theme: { colors, spacing } }) => css`
    padding: ${spacing(3)};
    font-size: 12px;
    background: ${colors.cardFront};
    font-weight: 400;
    width: 165px;
    height: 262px;
    text-align: left;
    border-radius: 16px;

    p {
      opacity: 0.5;
      color: ${colors.secondary};
    }
  `
)

export const BackCardContainer = styled(Common.Card)<ICustomStyledProps>(
  ({ theme: { spacing } }) => css`
    padding: ${spacing(3)};
    text-align: left;
    width: 165px;
    height: 262px;
    border-radius: 16px;
  `
)

export const RoomNumber = styled.p<ICustomStyledProps>(
  () => css`
    font-size: 62px;
    text-align: right;
    font-weight: 500;
    margin: 0;
  `
)

export const RoomNumberLegend = styled.span<ICustomStyledProps>(
  ({ theme: { colors } }) => css`
    color: ${colors.secondary};
    text-align: right;
    font-size: 8px;
  `
)

export const RoomCard = () => {
  return (
    <RoomCardContainer mr={4}>
      <Common.SvgIcon
        src={HotelLogo}
        mb={4}
      />
      <Typography.Body2 s={13}>Welcome!</Typography.Body2>
      <Typography.Body2
        mb={2}
        s={13}
      >
        We&apos;re happy, you chose our hotel for your stay.
      </Typography.Body2>
      <RoomNumber>305</RoomNumber>
      <RoomNumberLegend>Room number</RoomNumberLegend>
    </RoomCardContainer>
  )
}

export const BackCard = () => {
  return (
    <BackCardContainer>
      <Typography.Body2
        mb={6}
        s={10}
        secondary
      >
        On the departure day your room remains at your disposal until 12:00 AM.
        Please return your key card at reception box before leaving.
      </Typography.Body2>
      <Typography.Body2
        mb={2}
        s={10}
        secondary
      >
        WiFi password: guest/hotellogo
      </Typography.Body2>
      <Common.SvgIcon
        mb={2}
        w="22px"
        h="24px"
        src={cardChip}
      />
      <Typography.Body2
        s={10}
        secondary
      >
        www.hotellogo.com
      </Typography.Body2>
    </BackCardContainer>
  )
}
