/* eslint-disable no-console */
import { yupResolver } from '@hookform/resolvers/yup'
import { KioskViewContainer } from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import FormYourInfoKiosk from 'components/_forms/FormYourInfoKiosk'
import BackNextButtonsKiosk from 'components/_views/ReservationDetailsSteps/BackNextButtonsKiosk'
import ReservationSteps from 'components/_views/ReservationDetailsSteps/ReservationSteps'
import { RESERVATION_STEP_DETAILS_INFO } from 'constants/app'
import {
  ROUTE_MAKE_RESERVATION_PAYMENT,
  ROUTE_MAKE_RESERVATION_ROOMS_FOUND,
} from 'constants/routes'
import { useRooms } from 'logic/useRooms'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BookingData, createBooking } from 'redux/slices/booking/bookingSlice'
import { RootState } from 'redux/store'
import { IYourInfoFormKiosk } from 'types/room'
import { reservationSchemaKiosk } from 'utils/validation/reservation'
import { v4 as uuidv4 } from 'uuid'

const formatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

const ViewMakeReservationDetailsInfoKiosk = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { searchData } = useRooms({})
  const bookingStatus = useSelector((state: RootState) => state.booking.status)
  const bookingError = useSelector((state: RootState) => state.booking.error)
  const selectedRoom = useSelector(
    (state: RootState) => state.room.selectedRoom
  )

  const formReservation = useForm<IYourInfoFormKiosk>({
    mode: 'onTouched',
    resolver: yupResolver(reservationSchemaKiosk),
    defaultValues: {
      firstName: '',
      lastName: '',
      country: '',
      phone: '',
      email: '',
      terms: false,
    },
  })

  const handleReservationSubmit: SubmitHandler<IYourInfoFormKiosk> = () => {
    if (!selectedRoom) {
      console.error('No room selected')
      return
    }

    const bookingData: BookingData = {
      amount: selectedRoom.price.toString(),
      date_from: formatDate(new Date(searchData?.stayDate?.startDate || '')),
      date_to: formatDate(new Date(searchData?.stayDate?.endDate || '')),
      number_of_adults: searchData?.adults || 0,
      number_of_children: searchData?.children || 0,
      payment_profile_uuid: '4607aef7-8a1d-4f25-92f6-d5bccf361c7e',
      payment_type: 'repellat',
      room_uuid: selectedRoom.uuid,
      // users_uuid: 'd999de13-30aa-4ca6-9e7d-9b2c07ddb9f6',
      uuid: uuidv4(),
      email: formReservation?.watch()?.email,
      first_name: formReservation?.watch()?.firstName,
      last_name: formReservation?.watch()?.lastName,
      phone_number: formReservation?.watch()?.phone,
    }

    dispatch(createBooking(bookingData))
  }

  useEffect(() => {
    if (bookingStatus === 'succeeded') {
      navigate(ROUTE_MAKE_RESERVATION_PAYMENT)
    } else if (bookingStatus === 'failed') {
      console.error(bookingError)
    }
  }, [bookingStatus, bookingError, navigate])

  return (
    <KioskViewContainer
      pl={6}
      pr={6}
      pt={3}
    >
      <ReservationSteps step={RESERVATION_STEP_DETAILS_INFO} />
      <Typography.H4
        mb={1}
        mt={2}
        align="center"
        secondary
      >
        {t('reservationDetails.header')}
      </Typography.H4>
      <Typography.Body2
        mb={2}
        secondary
        align="center"
      >
        {t('reservationDetails.desc')}
      </Typography.Body2>
      <form onSubmit={formReservation.handleSubmit(handleReservationSubmit)}>
        <FormYourInfoKiosk form={formReservation} />
      </form>
      <BackNextButtonsKiosk
        handleBackButton={() => navigate(ROUTE_MAKE_RESERVATION_ROOMS_FOUND)}
        handleNextButton={formReservation.handleSubmit(handleReservationSubmit)}
      />
    </KioskViewContainer>
  )
}

export default ViewMakeReservationDetailsInfoKiosk
