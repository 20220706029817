import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import apiClient from 'api/client'
import { AxiosError } from 'axios'

export interface BookingData {
  uuid?: string
  users_uuid?: string
  room_uuid: string
  payment_type?: string
  payment_profile_uuid?: string
  refund_status?: string
  date_from: string
  date_to: string
  created_at?: string
  updated_at?: string
  amount: string
  number_of_adults: number
  number_of_children: number
  email?: string
  first_name?: string
  last_name?: string
  phone_number?: string
}

export interface BookingResponse {
  data: {
    uuid: string
    users_uuid: string
    payment_type: string
    payment_profile_uuid: string
    status: string
    refund_status: string | null
    date_from: string
    date_to: string
    checked_in_at: string | null
    checked_out_at: string | null
    reservation_code: string | null
    pin_code: string | null
    amount: string
    refund_amount: string | null
    created_at: string
    updated_at: string
    number_of_adults: number
    number_of_children: number
  }
}

interface BookingError {
  message: string
}

interface BookingState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
  bookingDetails: BookingResponse | null
}

const initialState: BookingState = {
  status: 'idle',
  error: null,
  bookingDetails: null,
}

export const createBooking = createAsyncThunk<
  BookingResponse,
  BookingData,
  { rejectValue: BookingError }
>('booking/createBooking', async (bookingData, { rejectWithValue }) => {
  try {
    const response = await apiClient.post<BookingResponse>(
      '/booking',
      bookingData
    )
    return response.data
  } catch (err) {
    const error: AxiosError<BookingError> = err as AxiosError<BookingError>
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const checkIn = createAsyncThunk<
  BookingResponse,
  string,
  { rejectValue: BookingError }
>('booking/checkIn', async (bookingUuid, { rejectWithValue }) => {
  try {
    const response = await apiClient.post<BookingResponse>(
      `/booking/${bookingUuid}/check-in`
    )
    return response.data
  } catch (err) {
    const error: AxiosError<BookingError> = err as AxiosError<BookingError>
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const cancelBooking = createAsyncThunk<
  BookingResponse,
  string,
  { rejectValue: BookingError }
>('booking/cancel', async (bookingUuid, { rejectWithValue }) => {
  try {
    const response = await apiClient.post<BookingResponse>(
      `/booking/${bookingUuid}/cancel`
    )
    return response.data
  } catch (err) {
    const error: AxiosError<BookingError> = err as AxiosError<BookingError>
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const checkOut = createAsyncThunk<
  BookingResponse,
  string,
  { rejectValue: BookingError }
>('booking/checkOut', async (bookingUuid, { rejectWithValue }) => {
  try {
    const response = await apiClient.post<BookingResponse>(
      `/booking/${bookingUuid}/check-out`
    )
    return response.data
  } catch (err) {
    const error: AxiosError<BookingError> = err as AxiosError<BookingError>
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    resetBookingStatus(state: BookingState) {
      state.status = 'idle'
      state.error = null
      state.bookingDetails = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createBooking.pending, (state: BookingState) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(
        createBooking.fulfilled,
        (state: BookingState, action: PayloadAction<BookingResponse>) => {
          state.status = 'succeeded'
          state.bookingDetails = action.payload
        }
      )
      .addCase(
        createBooking.rejected,
        (
          state: BookingState,
          action: PayloadAction<BookingError | undefined>
        ) => {
          state.status = 'failed'
          state.error = action.payload?.message || null
        }
      )
      .addCase(checkIn.pending, (state: BookingState) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(
        checkIn.fulfilled,
        (state: BookingState, action: PayloadAction<BookingResponse>) => {
          state.status = 'succeeded'
          state.bookingDetails = action.payload
        }
      )
      .addCase(
        checkIn.rejected,
        (
          state: BookingState,
          action: PayloadAction<BookingError | undefined>
        ) => {
          state.status = 'failed'
          state.error = action.payload?.message || null
        }
      )
      .addCase(cancelBooking.pending, (state: BookingState) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(
        cancelBooking.fulfilled,
        (state: BookingState, action: PayloadAction<BookingResponse>) => {
          state.status = 'succeeded'
          state.bookingDetails = action.payload
        }
      )
      .addCase(
        cancelBooking.rejected,
        (
          state: BookingState,
          action: PayloadAction<BookingError | undefined>
        ) => {
          state.status = 'failed'
          state.error = action.payload?.message || null
        }
      )
      .addCase(checkOut.pending, (state: BookingState) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(
        checkOut.fulfilled,
        (state: BookingState, action: PayloadAction<BookingResponse>) => {
          state.status = 'succeeded'
          state.bookingDetails = action.payload
        }
      )
      .addCase(
        checkOut.rejected,
        (
          state: BookingState,
          action: PayloadAction<BookingError | undefined>
        ) => {
          state.status = 'failed'
          state.error = action.payload?.message || null
        }
      )
      .addMatcher(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        action => action.type.endsWith('/fulfilled'),
        (state: BookingState) => {
          state.status = 'idle'
          state.error = null
          state.bookingDetails = null
        }
      )
  },
})

export const { resetBookingStatus } = bookingSlice.actions
export default bookingSlice.reducer
