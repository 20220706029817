import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useEffect, useState } from 'react'
import {
  fetchRooms,
  selectRooms,
  selectRoomSearchParams,
} from 'redux/slices/room'
import { IRoomsMeta } from 'types/room'

export const useRooms = (meta: IRoomsMeta) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    void dispatch(fetchRooms(meta))
  }, [meta.page])

  const { rooms, isLoading, pagination } = useAppSelector(selectRooms)
  const searchData = useAppSelector(selectRoomSearchParams)

  const [sortedItems, setSortedItems] = useState(rooms)
  const [sortOrder, setSortOrder] = useState('desc')

  const handleSort = () => {
    if (sortOrder === 'desc') {
      setSortOrder('asc')
      const sorted = [...rooms].sort(
        (a, b) => parseFloat(b.price) - parseFloat(a.price)
      )

      setSortedItems(sorted)
    } else {
      setSortOrder('desc')
      const sorted = [...rooms].sort(
        (a, b) => parseFloat(a.price) - parseFloat(b.price)
      )

      setSortedItems(sorted)
    }
  }

  useEffect(() => {
    handleSort()
  }, [rooms])

  return {
    handleSort,
    sortedItems,
    sortOrder,
    isLoading,
    pagination,
    searchData,
  }
}
